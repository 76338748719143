import React, { useEffect, useState } from "react";
import "./Home.css";
import Navbar from "../../components/Navbar/Navbar";
import EarningCallCard from "../../components/EarningCallCard/EarningCallCard.js";
import Footer from "../../components/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import Helmet from "react-helmet";

const Home = () => {
  const [companyName, setCompanyName] = useState("");
  const [quarter, setQuarter] = useState("");
  const [year, setYear] = useState("");
  const [data, setData] = useState(null);

  const handleSearch = async () => {
    if (companyName === "" || quarter === "" || year === "") {
      toast.warning("Fill all the fields");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}fetch-bucket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companyName: companyName,
            quarter: quarter,
            year: year,
          }),
        }
      );

      const jsonData = await response.json();
      if (jsonData.error === "File not found") {
        toast.error("File not found");
        return;
      }
      setData(jsonData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchBasicFiles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}fetch-basic-files`
        );
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBasicFiles();
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>
          Earnings Calls: Stay Updated with Timely Financial Information
        </title>
      </Helmet>
      <Navbar />
      <div className="inner-container">
        <div className="search-container">
          <div className="fields">
            <input
              type="number"
              placeholder="Year..."
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
            <input
              type="text"
              placeholder="Company..."
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Quarter..."
              value={quarter}
              onChange={(e) => setQuarter(e.target.value)}
            />
          </div>
          <button onClick={handleSearch}>Search</button>
        </div>

        {data === null
          ? ""
          : data.map((item, index) => {
            return (
              <EarningCallCard
                key={index}
                data={item}
                companyName={companyName}
                quarter={quarter}
                year={year}
              />
            );
          })}
      </div>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Home;
