import React from 'react'
import './Contact.css';

const Contact = () => {
    return (
        <div className='contact-us'>
            <div className="contact-us-container">
                <div className="contact-us-left">
                    <h1 className="contact-us-title">Contact Us</h1>
                    <p className="contact-us-description">Leave your email and we will get back to you within 24 hours.</p>
                    <div className="contact-us-info">
                        <div className="contact-us-info-item">
                            <span className="contact-us-info-label">Email</span>
                            <span className="contact-us-info-value">company@dataknobs.com</span>
                        </div>
                        <div className="contact-us-info-item">
                            <span className="contact-us-info-label">Phone</span>
                            <span className="contact-us-info-value">+1 (425) 341-1222</span>
                        </div>
                        <div className="contact-us-info-item">
                            <span className="contact-us-info-label">Working Hours</span>
                            <span className="contact-us-info-value">9am - 7 pm (Pacific Time - Los Angeles)</span>
                        </div>
                    </div>
                </div>
                <div className="contact-us-right">
                    <form className="contact-us-form">
                        <div className="contact-us-form-group">
                            <label className="contact-us-form-label">Full Name:</label>
                            <input type="text" className="contact-us-form-input" required />
                        </div>
                        <div className="contact-us-form-group">
                            <label className="contact-us-form-label">Phone Number:</label>
                            <input type="tel" className="contact-us-form-input" required />
                        </div>
                        <div className="contact-us-form-group">
                            <label className="contact-us-form-label">Email:</label>
                            <input type="email" className="contact-us-form-input" required />
                        </div>
                        <div className="contact-us-form-group">
                            <label className="contact-us-form-label">Company:</label>
                            <input type="text" className="contact-us-form-input" required />
                        </div>
                        <div className="contact-us-form-group">
                            <label className="contact-us-form-label">Message:</label>
                            <textarea className="contact-us-form-textarea" required></textarea>
                        </div>
                        <button type="submit" className="contact-us-form-button">Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact
