import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
import './EarningCallDetail.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { useData } from '../../DataContext.js';
import { motion, useInView, useAnimation } from "framer-motion"
import Helmet from 'react-helmet';

const EarningCallDetail = () => {
  // for animation
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  const { apiData } = useData();
  const id = useParams();
  const arr = id.param.split('-');
  const year = arr[0];
  const quarter = arr[1];
  const companyName = arr[2];

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    }
  }, [isInView])

  return (
    <div className='detail-outer-container'>
       <Helmet>
        <title>{`Earning Call: ${year}-${quarter}-${companyName}`}</title>
        <meta
          name="description"
          content={`Earning call information for ${year}-${quarter}-${companyName}. Stay informed with the latest updates on the Earning Call for ${companyName} in ${quarter} ${year}. Explore financial reports, insights, market trends, and investment opportunities. Gain in-depth analysis of ${companyName}'s performance and growth.`}
        />
        <meta
          name="keywords"
          content="earnings, calls, earning-calls, earning-call, investments, stocks, financial, reports, insights, market, trends, updates, opportunities, analysis, finance, performance, information, growth."
        />
      </Helmet>
      <Navbar />
      <div className="inner-container">

        <motion.div className='earning-call-card1'
          ref={ref}
          variants={{ hidden: { opacity: 0, y: -100 }, visible: { opacity: 1, y: 0 } }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.5, delay: 0.25 }}
        >

          <h3>Summary</h3>
          <p>{apiData&&apiData.summary}</p>

        </motion.div>

        {
          apiData &&
          apiData.questions.map((item, index) => {
            const questionKey = `question${index + 1}`;
            const answerKey = `answer${index + 1}`;
            return (
              <motion.div
                key={index}
                className='earning-call-card1'
                ref={ref}
                variants={{ hidden: { opacity: 0, y: -100 }, visible: { opacity: 1, y: 0 } }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.5, delay: 0.25 }}
              >
                <h3>{`${index + 1}) ${item[questionKey]}`}</h3>
                <p>{item[answerKey]}</p>
              </motion.div>
            );
          })
        }
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default EarningCallDetail




