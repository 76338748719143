import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import './EarningTips.css';
import Navbar from '../../components/Navbar/Navbar';
import Helmet from 'react-helmet';

const EarningTips = () => {
    const [data, setData] = useState(null);
    const [showDescription, setShowDescription] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        fetch("/earningTips.json")
            .then(response => response.json())
            .then(sdata => {
                setData(sdata);
                setShowDescription(new Array(sdata.length).fill(false));
            })
            .catch(error => {
                setData([]);
                console.error(error)
            });
    }, []);

    const toggleDescription = (index) => {
        const newShowDescription = [...showDescription];
        newShowDescription[index] = !newShowDescription[index];
        setShowDescription(newShowDescription);
        setSelectedIndex(index);
    };

    return (
        <div className='outer-container'>
            <Helmet>
                <title>Earning Tips: Boost Your Financial Success with Expert Insights</title>
                <meta
                    name="description"
                    content="Unlock the potential of your investments with our expert earning tips. Stay ahead in the financial game. Our exclusive tips are meticulously crafted to guide you through the intricacies of investment, ensuring not just financial growth, but a strategic advantage in navigating the ever-evolving landscape of opportunities."
                />
                <meta
                    name="keywords"
                    content="earnings, investments, stocks, financial, reports, insights, market, trends, updates, opportunities, analysis, finance, performance, information, growth."
                />
            </Helmet>
            <Navbar />

            {data === null ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                    <PulseLoader style={{ alignContent: 'center' }} color={'#36D7B7'} size={13} />
                </div>
            ) : data.length === 0 ? (
                <p>No data Available</p>
            ) : (
                data.map((item, index) => (
                    <div key={index} className="article-container">
                        <h3>{item.title}</h3>
                        <p>{item.articleSummary}</p>
                        {item.description && showDescription[index] && <p>{item.description}</p>}
                        <button className='read-more-button' onClick={() => toggleDescription(index)}>
                            {showDescription[index] ? "Read Less" : "Read More"}
                        </button>
                    </div>
                ))
            )
            }
        </div>
    )
}

export default EarningTips
