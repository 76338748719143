import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './EarningCallCard.css';
import { motion, useInView, useAnimation } from "framer-motion"
import { useData } from '../../DataContext.js';

const EarningCallCard = ({ data, companyName, quarter, year}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const mainControls = useAnimation();
    const { setApiData } = useData();
    const navigate = useNavigate();
   
    const handleCardClick = () => {
        setApiData(data);
        if(companyName === '' && quarter === '' && year === '') {
            quarter = 'Q1';
            year = '2023';
            const parts = data.title.split('-');
            companyName = parts[0];
        }
        navigate(`/earning-calls/${year}-${quarter}-${companyName}`);
    };

    useEffect(() => {
        if (isInView) {
            //fire the animation
            mainControls.start("visible");
        }
    }, [isInView])

    return (
        <motion.div className='earning-call-card' onClick={handleCardClick}
            ref={ref}
            variants={{ hidden: { opacity: 0, y: -100 }, visible: { opacity: 1, y: 0 } }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
        >

            <div className="heading">
                <h3>{data.title}</h3>
                <span>{year}</span>
            </div>

        </motion.div>
    )
}

export default EarningCallCard;
