import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Navbar.css'

const Navbar = () => {

  return (
    <div className="nav-container">
      <div className="logo">
        <Link to="/"><span>E</span>arning <span>C</span>alls</Link>
      </div>
      <div className='mobile'>
        <div className="menu">
          <ul className='menu-items'>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/earning-tips">Earning Tips</Link></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar