import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Home from "./pages/Home/Home";
import EarningTips from "./pages/EarningTips/EarningTips";
import EarningCallDetail from "./pages/EarningCallDetail/EarningCallDetail";
import { DataProvider } from "./DataContext";
import Helmet from 'react-helmet';
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";

function App() {
  return (
    <DataProvider>
      <BrowserRouter>
        <Helmet>
          <title>Earnings Calls: Stay Updated with Timely Financial Information</title>
          <meta
            name="description"
            content="Access real-time earnings call updates for informed investment decisions, empowering your financial strategy. Stay ahead with timely insights into investment opportunities. Elevate your decision-making with up-to-the-minute financial information."
          />
          <meta
            name="keywords"
            content="earnings, investments, stocks, financial, reports, insights, market, trends, updates, opportunities, analysis, finance, performance, information, growth."
          />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/earning-calls" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/earning-calls/:param" element={<EarningCallDetail />} />
          <Route path="/earning-tips" element={<EarningTips />} />
        </Routes>
      </BrowserRouter>
    </DataProvider>
  );
}

export default App;